<template>
  <Page
    @cta:click="onClick"
    :ctaClass="'danger'"
    :ctaLabel="'Back'"
    :title="'Add user'"
    class="c-add-user"
  >
    <template #content>
      <el-steps
        :active="activeStepBar"
        class="c-add-user__tabs"
        finish-status="success"
      >
        <el-step title="User Information"></el-step>
        <el-step title="Personal Record"></el-step>
        <el-step title="Physical Appearance and Life style"></el-step>
        <el-step title="Education and Profession"></el-step>
        <el-step title="Family Details"></el-step>
      </el-steps>
      <UserInformationForm
        @data:create="onCreate"
        class="c-add-user__form"
        v-if="tab === 1"
      />

      <PersonalRecordForm
        @data:create="onCreate"
        class="c-add-user__personal-record-form"
        v-if="tab === 2"
      />

      <PhysicalAppAndLifeStyleForm
        @data:create="onCreate"
        class="c-add-user__physical-app"
        v-if="tab === 3"
      />

      <EducationAndProfessionForm
        @data:create="onCreate"
        class="c-add-user__education-profession"
        v-if="tab === 4"
      />

      <FamilyDetailForm
        @user:create="onUserCreate"
        class="c-add-user__family-detail"
        v-if="tab === 5"
      />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import UserInformationForm from './UserInformationForm'
import PersonalRecordForm from './PersonalRecordForm'
import PhysicalAppAndLifeStyleForm from './PhysicalAppAndLifeStyleForm'
import EducationAndProfessionForm from './EducationAndProfessionForm'
import FamilyDetailForm from './FamilyDetailForm'

export default {
  components: {
    FamilyDetailForm,
    UserInformationForm,
    PhysicalAppAndLifeStyleForm,
    PersonalRecordForm,
    EducationAndProfessionForm,
    Page
  },

  data () {
    return {
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'users' })
    },

    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onUserCreate () {
      this.onClick()

      this.$router.push({ name: 'users' })
    },

    next () {
      if (this.activeStepBar++ > 4) this.activeStepBar = 0
    }
  },

  name: 'AddUser'
}
</script>

<style lang="scss">
.c-add-user {
  &__form {
    padding: 1rem;
  }

  &__tabs {
    padding: 1rem 0;
  }
}
</style>
